import { call, put } from 'redux-saga/effects'

import * as userActions from '@/redux/actions/user'
import * as claimActions from '@/redux/actions/claims'

import type { TEntity } from '../constants'
import { ENTITIES } from '../constants'

import * as AgencyApi from '@/redux/api/agencies'
import * as ClaimsApi from '@/redux/api/claims'
import * as MyAppClaimsApi from '@/redux/api/my_app_claims'
import * as CompaniesApi from '@/redux/api/companies'
import * as MemberBenefitsApi from '@/redux/api/member_benefits'
import * as PurchasesApi from '@/redux/api/purchases'
import * as RelationsApi from '@/redux/api/relations'
import * as PoliciesApi from '@/redux/api/policies'
import * as ProductsApi from '@/redux/api/products'
import * as PaymentsApi from './payments'
import * as NotificationsApi from '@/redux/api/notifications'
import * as QuestionsApi from '@/redux/api/questions'
import * as TermsOfUseApi from '@/redux/api/terms'
import * as UserApi from '@/redux/api/user'
import * as UsersApi from '@/redux/api/users'
import * as SupportApi from '@/redux/api/support'
import * as TipsApi from '@/redux/api/tips'
import * as TranslationsApi from '@/redux/api/translations'
import * as FlashNewsApi from '@/redux/api/flash_news'

import type { IApiResponse } from '@/utils/api_wrapper'
import toast from '@/utils/toast'
import i18n from '@/utils/i18n'

import { IS_WEB } from '@/constants/platform'

function * callApi (apiFunction: any, ...params: any) {
  const result: IApiResponse<any> = yield call(apiFunction, ...params)

  if (result.statusCode === 401) {
    toast(result.message ?? i18n.t('Uw sessie is verlopen. Log alstublieft opnieuw in.'))

    if (IS_WEB) {
      yield put(userActions.logout())
    } else {
      yield put(userActions.lock())
    }
  }

  if (result.statusCode === 409) {
    yield put(claimActions.serviceUnavailable(result.message ?? ''))
  }

  return result
}

interface IApiFunction {
  GET?: (id?: string, params?: any) => Promise<IApiResponse<unknown>>
  POST?: (params?: any) => Promise<IApiResponse<unknown>>
  PUT?: (id: string, params?: any) => Promise<IApiResponse<unknown>>
  DELETE?: (id: string) => Promise<IApiResponse<unknown>>
}

export const apiFunctions: Record<TEntity, IApiFunction> = {
  [ENTITIES.AGENCY]: {
    GET: AgencyApi.getAgency
  },
  [ENTITIES.AGENCY_PRINTOUT]: {
    GET: AgencyApi.getAgencyPrintout
  },
  [ENTITIES.MEMBER_BENEFIT]: {
    GET: MemberBenefitsApi.getMemberBenefit,
    PUT: MemberBenefitsApi.joinCampaign
  },
  [ENTITIES.MEMBER_BENEFITS]: {
    GET: MemberBenefitsApi.getMemberBenefits
  },
  [ENTITIES.RELATION]: {
    GET: RelationsApi.getRelation,
    POST: RelationsApi.postRelationInvite,
    DELETE: RelationsApi.deleteRelation
  },
  [ENTITIES.RELATIONS]: {
    GET: RelationsApi.getRelations
  },
  [ENTITIES.REPAIRER_CATEGORIES]: {
    GET: CompaniesApi.getClaimCompanyData
  },
  [ENTITIES.SEARCH_REPAIRERS]: {
    POST: CompaniesApi.searchClaimCompanies
  },
  [ENTITIES.PURCHASE]: {
    GET: PurchasesApi.getPurchase,
    DELETE: PurchasesApi.deletePurchase
  },
  [ENTITIES.PURCHASES]: {
    GET: PurchasesApi.getPurchases
  },
  [ENTITIES.GETRELATIONUSERTOKEN]: {
    GET: MyAppClaimsApi.getRelationUserToken
  },
  [ENTITIES.CLAIM]: {
    GET: MyAppClaimsApi.getClaim,
    DELETE: MyAppClaimsApi.deleteClaimConcept
  },
  [ENTITIES.CLAIMS]: {
    GET: MyAppClaimsApi.getClaims
  },
  [ENTITIES.HISTORICAL_CLAIMS]: {
    GET: ClaimsApi.getHistoricalClaims
  },
  [ENTITIES.CLAIM_DAMAGE_QUESTIONS]: {
    GET: ClaimsApi.getClaimDamageQuestions
  },
  [ENTITIES.REBUILD_VALUE_METER]: {
    POST: PoliciesApi.postRebuildValueMeter
  },
  [ENTITIES.PRODUCT]: {
    GET: ProductsApi.getProduct
  },
  [ENTITIES.PRODUCTS]: {
    GET: ProductsApi.getProducts
  },
  [ENTITIES.GENERATE_GREEN_CARD]: {
    POST: PoliciesApi.generateGreenCard
  },
  [ENTITIES.POLICY]: {
    GET: PoliciesApi.getPolicy
  },
  [ENTITIES.POLICIES]: {
    GET: PoliciesApi.getPolicies
  },
  [ENTITIES.NOTIFICATIONS]: {
    GET: NotificationsApi.getNotifications
  },
  [ENTITIES.NOTIFICATION]: {
    GET: NotificationsApi.getNotification,
    PUT: NotificationsApi.putNotification,
    DELETE: NotificationsApi.deleteNotification
  },
  [ENTITIES.PAYMENTS]: {
    GET: PaymentsApi.getPayments
  },
  [ENTITIES.QUESTIONS]: {
    GET: QuestionsApi.getQuestions
  },
  [ENTITIES.TERMS_OF_USE]: {
    GET: TermsOfUseApi.getTermsApp
  },
  [ENTITIES.USER]: {
    GET: UserApi.getUser
  },
  [ENTITIES.USERS]: {
    GET: UsersApi.getUsers
  },
  [ENTITIES.ABOUT_NH]: {
    GET: SupportApi.getAboutNh
  },
  [ENTITIES.WTD]: {
    GET: SupportApi.getWhatToDo
  },
  [ENTITIES.ABOUT_MEMBERSHIP]: {
    GET: SupportApi.getAboutMembership
  },
  [ENTITIES.TIPS]: {
    GET: TipsApi.getTips
  },
  [ENTITIES.TRANSLATIONS]: {
    GET: TranslationsApi.getTranslations
  },
  [ENTITIES.FLASH_NEWS]: {
    GET: FlashNewsApi.getFlashNews
  }
}

export default callApi
