import type { IRepairer } from '@/types/objects'
import * as CONSTANTS from '../constants/companies'

export type CompanyCategory =
  {
    id: number
    name: string
    brands: {
      id: number
      name: string
    }[]
  } | null
export interface ICompaniesState {

  repairers: IRepairer[]
  categories: CompanyCategory[]
  radiusOptions: number[] | null

}

export const initialState: ICompaniesState = {

  repairers: [],

  categories: [],

  radiusOptions: []

}

export default (state = initialState, action: { type: string, payload: { data: unknown } }): ICompaniesState => {
  switch (action?.type) {
    case 'GET_REPAIRER_CATEGORIES_SUCCESS':
      return {
        ...state,
        categories: action.payload.data.categories as ICompaniesState['categories'],
        radiusOptions: action.payload.data.radius as ICompaniesState['radiusOptions']
      }

    case CONSTANTS.SEARCH_COMPANIES_SUCCESS:

      return {
        ...state,
        repairers: action.payload.data.companies.claimCompanies as ICompaniesState['repairers']
      }
    default:
      return state
  }
}
