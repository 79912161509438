import type { Company } from '@/types/objects'

import * as CONSTANTS from '../constants/companies'

export const searchCompanies = (payload: { q: string, radius: number, category_id: string, brand_id: string | null }) => ({
  type: CONSTANTS.SEARCH_COMPANIES,
  payload
})

export const searchCompaniesSuccess = (companies: Company[]) => ({
  payload: { data: { companies } },
  type: CONSTANTS.SEARCH_COMPANIES_SUCCESS
})

export const citySelect = (cityOptions: { text: string, value: string }[]) => ({
  payload: { cityOptions },
  type: CONSTANTS.CITY_SELECT
})

export const searchCompaniesFailure = (error: string) => ({
  payload: { error },
  type: CONSTANTS.SEARCH_COMPANIES_FAILURE
})

export const clearCitySelect = () => ({
  type: CONSTANTS.CLEAR_CITY_SELECT
})
