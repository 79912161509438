import { CompanyTypes } from '@/types/objects'

export const COMPANY_TYPE_MAPPINGS = {
  [CompanyTypes.car]: ['Motor', 'Bestelauto', 'Vracht(Bestel)auto', 'Personenauto'],
  [CompanyTypes['recreational-vehicle']]: ['Camper', 'Caravan / Vouwwagen']
}

export const SEARCH_COMPANIES = 'SEARCH_COMPANIES'
export const SEARCH_COMPANIES_SUCCESS = 'SEARCH_COMPANIES_SUCCESS'
export const SEARCH_COMPANIES_FAILURE = 'SEARCH_COMPANIES_FAILURE'

export const CITY_SELECT = 'CITY_SELECT'
export const CLEAR_CITY_SELECT = 'CLEAR_CITY_SELECT'
