import ModalWeb from 'modal-enhanced-react-native-web'
import React, { useEffect, useRef } from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { Platform, StyleSheet, View } from 'react-native'
import ModalNative from 'react-native-modal'

import * as Bootstrap from '@/bootstrap'
import TouchableIcon from '@/components/touchables/TouchableIcon'
import ScrollView from '@/components/wrappers/ScrollView'
import Colors from '@/constants/Colors'
import Settings from '@/constants/Settings'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import { IS_WEB } from '@/constants/platform'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import usePrevious from '@/hooks/usePrevious'

interface TModalProps {
  children: React.ReactNode
  noPadding?: boolean
  onHide?: (...args: any[]) => any
  showClose?: boolean
  style?: StyleProp<ViewStyle>
  useScrollView?: boolean
  visible: boolean
}

export default function Modal ({ visible, onHide, ...props }: TModalProps) {
  useEffect(() => {
    if (Platform.OS === 'web') {
      function onKeyDownEvent (event: any) {
        const key = (event?.key || '').toLowerCase()

        if (key === 'escape' && visible && typeof onHide === 'function') {
          onHide()
        }
      }

      const listener = document.addEventListener('keydown', onKeyDownEvent, false)

      // eslint-disable-next-line consistent-return
      return () => {
        listener?.remove?.()
      }
    }
  }, [visible, onHide])

  const refModal = useRef<typeof ModalWeb>()

  const prevVisible = usePrevious(visible)

  useUpdateEffect(() => {
    const hasBecomeVisible = !prevVisible && visible

    if (hasBecomeVisible && IS_WEB) {
      setTimeout(() => {
        const contentElement = refModal?.current?.contentRef?.ref as HTMLDivElement

        if (!contentElement) {
          return
        }

        contentElement.setAttribute('tabindex', '0')
        contentElement.focus()
      })
    }
  }, [visible])

  const wrapperStyle = props.noPadding ? styles.wrapperNoPadding : styles.wrapper
  const Content = props.useScrollView
    ? (
      <Bootstrap.Column
        pointerEvents='box-none'
        spacing={0}
        span={{ lg: 6, md: 8, sm: 10, xl: 4, xs: 12 }}
        style={styles.column}
      >
        <ScrollView
          contentContainerStyle={props.style ? [wrapperStyle, props.style] : wrapperStyle}
          style={styles.scrollView}
        >
          {props.showClose
            ? (
              <TouchableIcon
                color={Colors.gray}
                icon={['fal', 'times']}
                onPress={onHide}
                touchableStyle={styles.icon}
              />
              )
            : null}

          {props.children}
        </ScrollView>
      </Bootstrap.Column>
      )
    : (
      <Bootstrap.Column
        spacing={0}
        span={{ lg: 6, md: 8, sm: 10, xl: 4, xs: 12 }}
        style={Styling.autoHorizontal}
      >
        <View style={props.style ? [wrapperStyle, props.style] : wrapperStyle}>
          {props.showClose
            ? (
              <TouchableIcon
                color={Colors.gray}
                icon={['fal', 'times']}
                onPress={onHide}
                touchableStyle={styles.icon}
              />
              )
            : null}
          {props.children}
        </View>
      </Bootstrap.Column>
      )

  if (Platform.OS === 'web') {
    // After upgrading to the latest version of react-native and react-native-web the following bug became present:
    // The visible property is not working in combination with animationType.
    if (!visible) {
      return null
    }

    return (
      <ModalWeb
        ref={refModal}
        transparent
        animationType='fade'
        onBackButtonPress={onHide}
        onBackdropPress={onHide}
        onRequestClose={onHide}
        presentationStyle='overFullScreen'
        style={styles.modal}
        supportedOrientations={Settings.supportedOrientations}
        visible={visible}
      >
        {Content}
      </ModalWeb>
    )
  }

  return (
    <ModalNative
      animationIn='fadeIn'
      animationOut='fadeOut'
      backdropColor={Colors.backdrop}
      backdropOpacity={1}
      isVisible={visible}
      onBackButtonPress={onHide}
      onBackdropPress={onHide}
      coverScreen={false}
    >
      {Content}
    </ModalNative>
  )
}

const wrapperStyle = {
  backgroundColor: 'white',
  borderRadius: Sizes.borderRadius
}

const styles = StyleSheet.create({
  column: {
    borderRadius: Sizes.borderRadius,
    flex: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'hidden'
  },
  icon: {
    position: 'absolute',
    right: 15,
    top: 15,
    zIndex: 1
  },
  modal: {
    backgroundColor: Colors.backdrop,
    margin: 0
  },
  scrollView: {
    flexGrow: 0,
    marginBottom: 'auto',
    marginTop: 'auto'
  },
  wrapper: {
    ...wrapperStyle,
    paddingHorizontal: Sizes.modalPaddingHorizontal,
    paddingVertical: Sizes.modalPaddingVertical
  },
  wrapperNoPadding: wrapperStyle
})
