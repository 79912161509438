/* eslint-disable @typescript-eslint/return-await */

import { Platform } from 'react-native'
import type { AxiosRequestConfig, CancelTokenSource } from 'axios'
import axios from 'axios'
import { v4 as uuid } from 'uuid'

import prototype from './prototype'
import oldApi from './api'
import Crashlytics from './firebase/crashlytics'

import type { Dict } from '@/redux/types'
import Settings from '@/constants/Settings'

import type { Relation, User } from '@/types/objects'

import { lock, logout } from '@/redux/actions/user'
import { IS_WEB } from '@/constants/platform'
import type { Store } from 'redux'

import packageJson from '../../package.json'

type IApiResponseData<T> = {
  success: true
  data: T
} | {
  success: false
  data?: false
}

export type IApiResponse<T> =
  { statusCode: number, message?: string, tokenExpiresAt: string } &
  IApiResponseData<T>

export interface ISqlApiResponse<T> {
  success: boolean
  status: number
  statusMessage: string
  data: T
}

interface IUserParams extends Dict<string> {
  token: User['token']
  userId: User['id']
  relationId: Relation['id']
  relationNumber: Relation['relationNumber']
}

class ApiWrapper {
  private token: IUserParams['token'] | undefined = undefined

  private userId: IUserParams['userId'] | undefined = undefined

  private relationId: IUserParams['relationId'] | undefined = undefined

  private relationNumber: IUserParams['relationNumber'] | undefined = undefined

  private cancelSources: Dict<CancelTokenSource> = {}

  public setUserParams = (params: Partial<IUserParams>) => {
    this.token = params?.token ?? this.token
    this.userId = params?.userId ?? this.userId
    this.relationId = params?.relationId ?? this.relationId
    this.relationNumber = params?.relationNumber ?? this.relationNumber

    oldApi.updateConfig(params)
  }

  public getUserParams = () => ({
    token: this.token,
    userId: this.userId,
    relationId: this.relationId,
    relationNumber: this.relationNumber
  })

  public reset = () => {
    this.token = undefined
    this.userId = undefined
    this.relationId = undefined
    this.relationNumber = undefined

    oldApi.reset()
  }

  private readonly createCancelToken = () => {
    const source = axios?.CancelToken?.source()
    const id = uuid()
    this.cancelSources[id] = source

    return { id, source }
  }

  private readonly logout = (): void => {
    if (process.env.JEST_WORKER_ID === undefined) {
      void import('@/redux/configureStore').then(({ store }: { store: Store }) => {
        if (IS_WEB) {
          store.dispatch(logout())
        } else {
          store.dispatch(lock())
        }
      })
    }
  }

  private readonly request = async <T>(
    url: string,
    method: AxiosRequestConfig['method'],
    params?: any,
    raw: boolean,
    removeBaseUrl: boolean
  ): Promise<IApiResponse<T>> => {
    const { id, source } = this.createCancelToken()

    let fullUrl = ''
    if (!removeBaseUrl) {
      const baseUrl = url.includes('my-app') ? Settings.claimUrl : Settings.apiUrl
      fullUrl = `${baseUrl}/${url}`
    } else {
      fullUrl = url
    }

    return await axios({
      cancelToken: source?.token,
      method,
      responseType: 'json',
      url: fullUrl,
      [method !== 'GET' ? 'data' : 'params']: prototype.toSnakeCase({
        ...params,
        ...this.getUserParams(),
        environment: Platform.OS,
        appVersion: packageJson.version
      })
    })
      ?.then(({ data, status }) => {
        if (raw) {
          return data
        }

        const statusCode = status
        const success = statusCode >= 200 && statusCode < 300

        delete this.cancelSources[id]

        if (statusCode > 403) {
          Crashlytics.recordAPIError(`API status code from then ${statusCode}`, {
            ...data,
            statusCode,
            url,
            method
          })
        }

        return {
          success,
          ...prototype.toCamelCase(data)
        }
      })
      ?.catch((error) => {
        delete this.cancelSources[id]

        const statusCode = error?.response?.data?.status_code || error?.response?.status
        const success = statusCode >= 200 && statusCode < 300

        if (statusCode > 403) {
          Crashlytics.recordAPIError(`API status code from catch ${statusCode}`, {
            ...error,
            statusCode,
            url,
            method
          })
        }

        if (statusCode === 503) {
          this.logout()
          Crashlytics.recordAPIError('User logged out because Betty 503', {
            ...error,
            statusCode,
            url,
            method
          })
        }

        const message = statusCode === 503 ? 'Deze applicatie is momenteel in gepland onderhoud. Probeer het graag over enkele uren nog eens.' : 'Er is iets fout gegaan, probeer het later opnieuw.'

        return {
          ...prototype.toCamelCase(error?.response?.data),
          success,
          statusCode,
          message: error?.response?.data?.message || message
        }
      })
  }

  public get = async <T>(url: string, params?: any, raw = false, removeBaseUrl = false) => this.request<T>(url, 'GET', params, raw, removeBaseUrl)

  public post = async <T>(url: string, params: any, raw = false, removeBaseUrl = false) => this.request<T>(url, 'POST', params, raw, removeBaseUrl)

  public put = async <T>(url: string, params: any, raw = false, removeBaseUrl = false) => this.request<T>(url, 'PUT', params, raw, removeBaseUrl)

  public delete = async <T>(url: string, raw = false, removeBaseUrl = false) => this.request<T>(url, 'DELETE', undefined, raw, removeBaseUrl)

  public cancelRequests = () => {
    Object.values(this.cancelSources).forEach((source) => source?.cancel())

    oldApi.cancelAll()
  }
}

export default new ApiWrapper()
