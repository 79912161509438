import React from 'react'
import { connect, useSelector } from 'react-redux'

import EmptyState from '@/components/EmptyState'
import Screen from '@/components/Screen'
import ListItemGreenCard from '@/screens/green_card/components/ListItemGreenCard'

import { apiCall } from '@/redux/actions/api'
import { ENTITIES } from '@/redux/constants'
import type { State as TState } from '@/redux/reducers'
import selectors from '@/redux/selectors'

import type { TNavigationProp } from '@/types/props'

import common from '@/utils/common'
import i18n from '@/utils/i18n'
import type { IGreenCardRow } from '@/redux/selectors/policies'
import { selectGreenCardRows } from '@/redux/selectors/policies'

interface TOwnProps {
  navigation: TNavigationProp
}

type TGreenCardsProps = TOwnProps & {
  refreshing: boolean
  apiCall: typeof apiCall
}

const GreenCards = (props: TGreenCardsProps) => {
  const { apiCall, refreshing } = props
  const rows = useSelector(selectGreenCardRows)

  const refresh = () => {
    return apiCall('GET', ENTITIES.POLICIES)
  }

  const renderItem = (row: IGreenCardRow) => {
    return (
      <ListItemGreenCard
        key={`list-item-green-card-${row.policy.id}`}
        row={row}
      />
    )
  }

  const renderEmptyState = () => {
    const message = refreshing
      ? i18n.t('De Verzekeringsbewijzen worden opgehaald')
      : i18n.t('U heeft (nog) geen Verzekeringsbewijzen. Het Nh1816 Verzekeringsbewijs vervangt de Groene Kaart voor motorrijtuigen.')

    return (
      <EmptyState message={message} />
    )
  }

  return (
    <Screen
      useRefreshControl
      refreshing={refreshing}
      onRefresh={refresh}
    >
      {(rows.length > 0)
        ? rows.map(renderItem)
        : renderEmptyState()}
    </Screen>
  )
}

GreenCards.navigationOptions = (props: TOwnProps) => ({
  title: common.getNavigationParam(props, 'title')
})

const mapStateToProps = (state: TState, ownProps: TOwnProps) => ({
  navigation: ownProps.navigation,
  refreshing: !(selectors.isLoading(ENTITIES.POLICIES)(state) == null)
})

export default connect(mapStateToProps, { apiCall })(GreenCards)
