import React from 'react'
import { ActivityIndicator, View, Modal, StyleSheet } from 'react-native'
import Colors from '@/constants/Colors'

const OverlaySpinner: React.FC<{ visible: boolean }> = ({ visible }) => (
  <View>
    <Modal
      animationType='fade'
      transparent
      visible={visible}
      statusBarTranslucent
      hardwareAccelerated
    >
      <View style={styles.container}>
        <ActivityIndicator
          color={Colors.primary}
          size='large'
          style={styles.activityIndicator}
        />
      </View>
    </Modal>
  </View>
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.15)'
  },
  activityIndicator: {
    borderWidth: 25,
    borderRadius: 10,
    borderColor: 'rgba(240, 243, 246, 1)',
    backgroundColor: 'rgba(240, 243, 246, 1)'
  }
})

export default OverlaySpinner
