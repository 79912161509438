import { library } from '@fortawesome/fontawesome-svg-core'

import { faArrowDown } from '@fortawesome/pro-solid-svg-icons/faArrowDown'
import { faArrowUp } from '@fortawesome/pro-solid-svg-icons/faArrowUp'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight'
import { faArrowDown as faArrowDownLight } from '@fortawesome/pro-light-svg-icons/faArrowDown'
import { faArrowUp as faArrowUpLight } from '@fortawesome/pro-light-svg-icons/faArrowUp'
import { faArrowLeft as faArrowLeftLight } from '@fortawesome/pro-light-svg-icons/faArrowLeft'
import { faArrowRight as faArrowRightLight } from '@fortawesome/pro-light-svg-icons/faArrowRight'
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus'
import { faMinus } from '@fortawesome/pro-regular-svg-icons/faMinus'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight'
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown'
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp'
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons/faFileAlt'
import { faFragile } from '@fortawesome/pro-solid-svg-icons/faFragile'
import { faSkiing } from '@fortawesome/pro-solid-svg-icons/faSkiing'
import { faEuroSign } from '@fortawesome/pro-solid-svg-icons/faEuroSign'
import { faTachometer } from '@fortawesome/pro-solid-svg-icons/faTachometer'
import { faCouch } from '@fortawesome/pro-solid-svg-icons/faCouch'
import { faHouse } from '@fortawesome/pro-solid-svg-icons/faHouse'
import { faCar } from '@fortawesome/pro-solid-svg-icons/faCar'
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp'
import { faPhonePlus } from '@fortawesome/pro-solid-svg-icons/faPhonePlus'
import { faGlobe } from '@fortawesome/pro-solid-svg-icons/faGlobe'
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome'
import { faHome as faHomeLight } from '@fortawesome/pro-light-svg-icons/faHome'
import { faFolder } from '@fortawesome/pro-solid-svg-icons/faFolder'
import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy'
import { faUserTie } from '@fortawesome/pro-solid-svg-icons/faUserTie'
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV'
import { faBackspace } from '@fortawesome/pro-solid-svg-icons/faBackspace'
import { faBackspace as faBackSpaceRegular } from '@fortawesome/pro-regular-svg-icons/faBackspace'
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons/faAsterisk'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faAddressCard } from '@fortawesome/pro-solid-svg-icons/faAddressCard'
import { faPlus as faPlusRegular } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons/faPaperPlane'
import { faInfo } from '@fortawesome/pro-solid-svg-icons/faInfo'
import { faComments } from '@fortawesome/pro-solid-svg-icons/faComments'
import { faLightbulb } from '@fortawesome/pro-solid-svg-icons/faLightbulb'
import { faFileCheck } from '@fortawesome/pro-solid-svg-icons/faFileCheck'
import { faCalendar as faCalendarLight } from '@fortawesome/pro-light-svg-icons/faCalendar'
import { faTimes as faTimesRegular } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faFire } from '@fortawesome/pro-solid-svg-icons/faFire'
import { faTint } from '@fortawesome/pro-solid-svg-icons/faTint'
import { faWind } from '@fortawesome/pro-solid-svg-icons/faWind'
import { faSnowflake } from '@fortawesome/pro-solid-svg-icons/faSnowflake'
import { faUmbrellaBeach } from '@fortawesome/pro-solid-svg-icons/faUmbrellaBeach'
import { faTag } from '@fortawesome/pro-solid-svg-icons/faTag'
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons/faSlidersH'
import { faTools } from '@fortawesome/pro-solid-svg-icons/faTools'
import { faQuestion } from '@fortawesome/pro-solid-svg-icons/faQuestion'
import { faCross } from '@fortawesome/pro-solid-svg-icons/faCross'
import { faGhost } from '@fortawesome/pro-solid-svg-icons/faGhost'
import { faCamera } from '@fortawesome/pro-solid-svg-icons/faCamera'
import { faImages } from '@fortawesome/pro-solid-svg-icons/faImages'
import { faImage } from '@fortawesome/pro-solid-svg-icons/faImage'
import { faLocation } from '@fortawesome/pro-solid-svg-icons/faLocation'
import { faBell } from '@fortawesome/pro-solid-svg-icons/faBell'
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock'
import { faFingerprint } from '@fortawesome/pro-solid-svg-icons/faFingerprint'
import { faSmile } from '@fortawesome/pro-solid-svg-icons/faSmile'
import { faUserLock } from '@fortawesome/pro-solid-svg-icons/faUserLock'
import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog'
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons/faUserPlus'
import { faCommentAltCheck } from '@fortawesome/pro-solid-svg-icons/faCommentAltCheck'
import { faUserTimes } from '@fortawesome/pro-solid-svg-icons/faUserTimes'
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil'
import { faTrash as faTrashSolid } from '@fortawesome/pro-solid-svg-icons/faTrash'
import { faCalendar } from '@fortawesome/pro-solid-svg-icons/faCalendar'
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock'
import { faKey } from '@fortawesome/pro-solid-svg-icons/faKey'
import { faLocationSlash } from '@fortawesome/pro-solid-svg-icons/faLocationSlash'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faCheck as faCheckRegular } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle'
import { faMotorcycle } from '@fortawesome/pro-solid-svg-icons/faMotorcycle'
import { faIslandTropical } from '@fortawesome/pro-solid-svg-icons/faIslandTropical'
import { faCaravan } from '@fortawesome/pro-solid-svg-icons/faCaravan'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
import { faGavel } from '@fortawesome/pro-solid-svg-icons/faGavel'
import { faRv } from '@fortawesome/pro-solid-svg-icons/faRv'
import { faShuttleVan } from '@fortawesome/pro-solid-svg-icons/faShuttleVan'
import { faTrailer } from '@fortawesome/pro-solid-svg-icons/faTrailer'
import { faTractor } from '@fortawesome/pro-solid-svg-icons/faTractor'
import { faGem } from '@fortawesome/pro-solid-svg-icons/faGem'
import { faShip } from '@fortawesome/pro-solid-svg-icons/faShip'
import { faChairOffice } from '@fortawesome/pro-solid-svg-icons/faChairOffice'
import { faBoxAlt } from '@fortawesome/pro-solid-svg-icons/faBoxAlt'
import { faTruck } from '@fortawesome/pro-solid-svg-icons/faTruck'
import { faMask } from '@fortawesome/pro-solid-svg-icons/faMask'
import { faSeedling } from '@fortawesome/pro-solid-svg-icons/faSeedling'
import { faRoad } from '@fortawesome/pro-solid-svg-icons/faRoad'
import { faBicycle } from '@fortawesome/pro-solid-svg-icons/faBicycle'

library.add(
  faArrowDown,
  faArrowUp,
  faArrowLeft,
  faArrowRight,
  faArrowDownLight,
  faArrowUpLight,
  faArrowLeftLight,
  faArrowRightLight,
  faBicycle,
  faPlus,
  faMinus,
  faChevronRight,
  faChevronLeft,
  faFileAlt,
  faFragile,
  faEuroSign,
  faTachometer,
  faCouch,
  faHouse,
  faCar,
  faPhone,
  faEnvelope,
  faWhatsapp,
  faPhonePlus,
  faGlobe,
  faHome,
  faHomeLight,
  faFolder,
  faCopy,
  faUserTie,
  faEllipsisV,
  faBackspace,
  faTimesRegular,
  faBackSpaceRegular,
  faBars,
  faTrash,
  faAsterisk,
  faInfoCircle,
  faTimes,
  faTimesCircle,
  faExclamationCircle,
  faCheckCircle,
  faAddressCard,
  faPlusRegular,
  faPaperPlane,
  faInfo,
  faComments,
  faLightbulb,
  faFileCheck,
  faTag,
  faAddressCard,
  faChevronDown,
  faChevronUp,
  faCalendarLight,
  faSlidersH,
  faTools,
  faQuestion,
  faCross,
  faGhost,
  faCamera,
  faImages,
  faImage,
  faLocation,
  faBell,
  faLock,
  faFingerprint,
  faSmile,
  faUserLock,
  faCog,
  faUserPlus,
  faCommentAltCheck,
  faUserTimes,
  faUser,
  faPencil,
  faTrash,
  faCalendar,
  faClock,
  faKey,
  faLocationSlash,
  faCheck,
  faQuestionCircle,
  faMotorcycle,
  faIslandTropical,
  faCaravan,
  faExclamationTriangle,
  faGavel,
  faRv,
  faShuttleVan,
  faTrailer,
  faTractor,
  faGem,
  faShip,
  faChairOffice,
  faBoxAlt,
  faTrashSolid,
  faTruck,
  faFire,
  faWind,
  faTint,
  faSnowflake,
  faUmbrellaBeach,
  faCheckRegular,
  faSkiing,
  faMask,
  faSeedling,
  faRoad
)
