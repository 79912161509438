import React from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import ButtonCircle from '@/components/buttons/ButtonCircle'
import CircleDistance from '@/components/CircleDistance'
import ListItem from '@/components/list_items/ListItem'
import TextHighlight from '@/components/TextHighlight'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import common from '@/utils/common'
import type { Company, ICarWindowRepairer } from '@/types/objects'
import selectors from '@/redux/selectors'

interface IProps {
  company: Company

  onPress?: (company: Company) => void
  query?: string
  style?: StyleProp<ViewStyle>
}

const ListItemCompany: React.FC<IProps> = ({ company, ...props }) => {
  const colors = useSelector(selectors.getThemeColors)

  const openEmail = () => common.openEmail(company?.emailAddress)
  const openPhoneNumber = () => common.openPhoneNumber(company?.phoneNumber)
  const openWebsite = () => common.openWebsite(company?.website)

  const cleanQuery = (props.query || '').trim().toLowerCase()

  return (
    <ListItem
      flex
      item={company}
      onPress={props.onPress}
      style={props.style}
    >
      <CircleDistance meters={company?.distance * 1000} />

      <View style={styles.container}>
        <TextHighlight
          query={cleanQuery}
          type='label'
        >
          {company?.name}
        </TextHighlight>
        <TextHighlight
          query={cleanQuery}
          type='paragraphLight'
        >
          {!(company as ICarWindowRepairer).chain ? `${company?.street} ${company?.houseNumber}` : 'Landelijke keten'}
        </TextHighlight>
        <TextHighlight
          query={cleanQuery}
          type='paragraphLight'
        >
          {!(company as ICarWindowRepairer).chain ? `${company?.zipCode} ${company?.city}` : 'Specifieke informatie niet beschikbaar'}
        </TextHighlight>
      </View>

      <View>
        <ButtonCircle
          small
          color={colors?.brand}
          disabled={!company?.phoneNumber}
          icon='phone'
          onPress={openPhoneNumber}
          style={Styling.marginVerticalTinyHalf}
        />
        <ButtonCircle
          small
          color={colors?.brand}
          disabled={!company?.emailAddress}
          icon='envelope'
          onPress={openEmail}
          style={Styling.marginVerticalTinyHalf}
        />
        <ButtonCircle
          small
          color={colors?.brand}
          disabled={!company?.website}
          icon='globe'
          onPress={openWebsite}
          style={Styling.marginVerticalTinyHalf}
        />
      </View>
    </ListItem>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: Sizes.spacingVertical
  }
})

export default ListItemCompany
