import React from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import ButtonCircle from '@/components/buttons/ButtonCircle'
import CircleDistance from '@/components/CircleDistance'
import ListItemCompany from '@/components/list_items/ListItemCompany'
import Modal from '@/components/wrappers/Modal'
import Text from '@/components/wrappers/Text'
import Colors from '@/constants/Colors'
import Sizes from '@/constants/Sizes'
import Styling from '@/constants/Styling'
import selectors from '@/redux/selectors'
import type { Company } from '@/types/objects'
import common from '@/utils/common'

interface IProps {
  company: Company
  onHide: () => void
  visible: boolean
}

const CompanyInfo: React.FC<{ company: Company }> = ({ company }) => {
  const colors = useSelector(selectors.getThemeColors)

  const openEmail = () => common.openEmail(company.emailAddress)
  const openPhoneNumber = () => common.openPhoneNumber(company.phoneNumber)
  const openWebsite = () => common.openWebsite(company.website)

  return (
    <View style={appStyles.container}>
      <View style={appStyles.contact}>
        <CircleDistance meters={company.distance * 1000} />

        <View style={appStyles.contactInfo}>
          <Text
            numberOfLines={2}
            type='label'
          >
            {company.name}
          </Text>
          <Text type='paragraphLight'>{`${company.street} ${company.houseNumber}`}</Text>
          <Text type='paragraphLight'>{`${company.zipCode} ${company.city}`}</Text>
        </View>
      </View>

      <View style={appStyles.buttons}>
        <ButtonCircle
          small
          color={colors?.brand}
          disabled={!company.phoneNumber}
          icon='phone'
          onPress={openPhoneNumber}
          style={Styling.marginVerticalTinyHalf}
        />
        <ButtonCircle
          small
          color={colors?.brand}
          disabled={!company.emailAddress}
          icon='envelope'
          onPress={openEmail}
          style={Styling.marginVerticalTinyHalf}
        />
        <ButtonCircle
          small
          color={colors?.brand}
          disabled={!company.website}
          icon='globe'
          onPress={openWebsite}
          style={Styling.marginVerticalTinyHalf}
        />
      </View>
    </View>
  )
}

const ModalCompany: React.FC<IProps> = (props) => (
  <Modal
    showClose
    onHide={props.onHide}
    visible={props.visible}
  >
    {Platform.OS === 'web'
      ? (
        <ListItemCompany
          company={props.company}
          query=''
          style={webStyles.listItem}
        />
        )
      : (
        <CompanyInfo company={props.company} />
        )}
  </Modal>
)

const appStyles = StyleSheet.create({
  buttons: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: 20
  },
  contact: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  contactInfo: {
    flex: 1,
    marginLeft: 5
  },
  container: {
    backgroundColor: Colors.white,
    flexDirection: 'column'
  }
})

const webStyles = StyleSheet.create({
  listItem: {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: Sizes.spacingHorizontal,
    paddingTop: 0
  }
})

export default ModalCompany
